import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import imagesLoaded from 'imagesloaded';
import 'intersection-observer';
import { vscroll, getTranslate } from './vscroll';


document.addEventListener( 'DOMContentLoaded', function () {
  onLoadCommon();
});


function onLoadCMS(cms) {
  // remove empty p tags
  const emptyPs = cms.querySelectorAll('p:empty');
  emptyPs.forEach(p => {
    p.remove();
  });

  // remove last of '.c-block-spot-arrow'
  const arrows = cms.querySelectorAll('.c-block-spot-arrow');
  const lastArrow = arrows[arrows.length - 1];
  if (lastArrow) {
    lastArrow.style.opacity = '0';
  }

  const cmsSliders = cms.querySelectorAll('.cms-slider .splide__list');
  cmsSliders.forEach(s => {
    // replace children, filtered by img or figure
    const children = Array.from(s.children).filter(c => c.tagName === 'IMG' || c.tagName === 'FIGURE');
    children.forEach(c => {
      c.classList.add('splide__slide');
    });
    s.replaceChildren(...children);
  });

}
function onLoadCommon() {

  const cms = document.querySelector('.cms');
  if (cms) {
    onLoadCMS(cms);
  }


  // accordion
  const accordions = document.querySelectorAll('.c-accordion');
  accordions.forEach(a => {
    const title = a.querySelector('.c-accordion__title');
    const content = a.querySelector('.c-accordion__content');
    title.addEventListener('click', () => {
      content.classList.toggle('is-open');
      title.classList.toggle('is-open');
      content.style.height = content.classList.contains('is-open') ? `${content.scrollHeight}px` : '0';
    });
  })

  const sliders = document.querySelectorAll('.splide');
  sliders.forEach(s => {
    new Splide( s, {
      type: 'loop',
      perPage: 1,
      autoplay: true,
      interval: 3000,
      arrows: false,
    } ).mount();
  });


  // tab page
  const tabSelector = document.getElementById('tab-selector');
  const tabContent = document.getElementById('tab-content');
  if (tabSelector && tabContent) {

    const tabItems = tabSelector.querySelectorAll('.c-tab-selector__tab');
    const tabContents = tabContent.querySelectorAll('.c-tab-content__item');

    tabItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        tabItems.forEach(i => {
          i.classList.remove('is-active');
        });
        item.classList.add('is-active');
        tabContents.forEach(c => {
          c.classList.remove('is-active');
        });
        const content = tabContents[index];
        content.classList.add('is-active');
        const itemHeight = content.getBoundingClientRect().height;
        tabContent.style.height = `${itemHeight}px`;
      });
    });
    const hash = location.hash;
    if (hash) {
      const title = hash.replace('#', '').replace('/', '');
      const target = tabSelector.querySelector(`[data-tab="${title}"]`);
      console.log(target)
      if (target) {
        target.click();
      } else {
        tabItems[0].click();
      }
    } else {
      tabItems[0].click();
    }
  }


  // header
  const header = document.getElementById('header');
  const hamburger = document.getElementById('hamburger');
  let isHeaderOpen = false;
  hamburger.addEventListener('click', () => {
    header.classList.toggle('is-open');
    isHeaderOpen = !isHeaderOpen;
  });

  let scrollTicking = false;
  let prevScrollTop = 0;
  window.addEventListener('scroll', () => {

    if (!scrollTicking) {
      window.requestAnimationFrame(() => {
        scrollTicking = false;
        const scrollTop = window.pageYOffset;
        if (scrollTop > 0) {
          header.classList.add('is-scrolled');
        } else {
          header.classList.remove('is-scrolled');
        }
        if (scrollTop > prevScrollTop && scrollTop > 100 && !isHeaderOpen) {
          header.classList.remove('is-up');
        }
        if (scrollTop < prevScrollTop) {
          header.classList.add('is-up');
        }
        prevScrollTop = scrollTop;
      });
      scrollTicking = true;
    }
  });

  const anchrs = document.querySelectorAll('a[href^="#"]');
  anchrs.forEach(a => {
    a.addEventListener('click', e => {
      e.preventDefault();
      const href = a.getAttribute('href');
      if ( href === '#' ) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  });

  function onLoad() {

    body.classList.add('is-loaded');
    if (fg && bg) {
      fg.classList.add('is-active');
      bg.classList.add('is-active');
    }
    if (kv) {
      kv.classList.remove('is-hidden');
    }
  }


  // all links
  const links = document.querySelectorAll('a');
  const body = document.querySelector('body');
  const pageHeader = document.getElementById('page-header');
  const fg = pageHeader && pageHeader.querySelector('.c-page-header__heading');
  const bg = pageHeader && pageHeader.querySelector('.c-page-header__bg');

  const kv = document.getElementById('animation-kv');
  // onLoad();
  window.addEventListener('pageshow', () => {
    onLoad();
  });

  const allIllustsHide = () => {
    if (pageHeader) {
      const allIllusts = pageHeader.querySelectorAll('.c-page-header__illust');
        if (allIllusts) {
        allIllusts.forEach(i => {
          i.classList.remove('is-active');
          /*
          const s = i.dataset.slug;
          if (s === slug) {
            i.classList.add('is-hidden');
          }
          */
        });
      }
    }
  }



  links.forEach(l => {
    l.addEventListener('click', e => {
      const href = l.getAttribute('href');
      const isHash = href && href.startsWith('#');
      const isExternal = l.getAttribute('target') === '_blank';
      // const slug = l.getAttribute('data-slug');
      const slug =  href.replace(/\/+$/, '').split('/').pop();
      if (isHash || isExternal) {
        return;
      }
      e.preventDefault();

      header.classList.remove('is-open');
      header.classList.add('is-up');
      body.classList.remove('is-loaded');

      const pageType = window.ktnzm.pageType;
      const prevPageType = sessionStorage.getItem('ktnzm:pageType');

      const pageOrArchive = (type) => type === 'page' || type === 'archive';

      if ( pageOrArchive(pageType) && pageOrArchive(prevPageType) ) {
        const bgList = window.ktnzm.bgList;
        if ( bgList.includes(slug) ) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        const bgSlug = bgList.find(i => i === slug);
        const alternatives = pageHeader && pageHeader.querySelectorAll('.c-page-header__alternatives__item');

        if (!bgSlug && pageHeader) {
          pageHeader.classList.add('is-hidden')
        }
        
        if (alternatives) {
          alternatives.forEach(a => {
            a.classList.remove('is-active');
          });
        }
        allIllustsHide();

        // hide
        setTimeout(() => {
          if ( fg && bg ) {
            bg.classList.add('is-hidden');
            fg.classList.remove('is-active');
          }
        }, 300);

        if (bgSlug) {
          if (pageHeader) {
            const illustEl = pageHeader.querySelector(`.c-page-header__illust[data-slug="${slug}"]`);
            const alternateEl = pageHeader.querySelector(`.c-page-header__alternatives__item[data-slug="${slug}"]`);
            if (alternateEl) {
              alternateEl.classList.add('is-active');
            }
            setTimeout(() => {
              if (illustEl) {
                illustEl.classList.add('is-show');
              }
            }, 300);
          }
        }
        setTimeout(() => {
          window.location.href = href;
        }, 600);
      } else if ( pageType === 'top') {
        if (kv) {
          kv.classList.add('is-hidden');
        }
        setTimeout(() => {
          window.location.href = href;
        }, 300);
      }
      else {
        setTimeout(() => {
          window.location.href = href;
        }, 300);
      }

      sessionStorage.setItem('ktnzm:pageType', pageType);
    });

  });

  kvIsLoaded(kv);


  setupIntersectionObserver();
}

function kvIsLoaded(kv) {
  if (!kv) { return; }

  function imagesLoadProgress(_instance, image) {
    // const delay = Math.random() * 1000;
    const element = image.img;

    const enableAnimation = [
      'animation-show',
      'animation-color',
    ]
    enableAnimation.forEach(className => {
      const delay = element.getAttribute(`data-${className}`)
      if ( delay !== null ) {
        setTimeout(() => {
          element.classList.add(className);
        }, delay);
      }
    });
  }

  const loader = new imagesLoaded( kv.querySelector('.c-akv__content') );
  loader.on( 'progress', imagesLoadProgress );
  const delayOnDone = 1500;
  loader.on( 'done', () => {
    const loadedObservers = document.querySelectorAll('.observe-kv-loaded');
    setTimeout(() => {
      kvAnimation(kv);
      loadedObservers.forEach(o => {
        o.classList.add('is-loaded');
      });
    }, delayOnDone)
  })


}

function kvAnimation(kv) {
  if (!kv) { return; }

  // animation productions

  // animation illustration
  const stateLen = 3
  const loopInterval = 600;
  const animationInterval = 200
  const nthAnimation = 4

  const illusts = kv.querySelectorAll('.c-akv__content__illust');
  const clearState = (illust) => {
    for (let i = 0; i < 5; i++) {
      illust.classList.remove(`state-0${i}`);
    }
    illust.classList.remove('is-animated');
  }

  const setRandomState = (illust) => {
    const stateIndex = Math.floor(Math.random() * stateLen);
    // remove all state
    // clearState(illust);
    // add state
    requestAnimationFrame(() => {
      illust.classList.add(`state-0${stateIndex}`);
    })
  }

  // infinite loop
  setInterval(() => {
    // random select illust
    const index = Math.floor(Math.random() * illusts.length);
    const illust = illusts[index];
    if ( illust.classList.contains('is-animated') ) {
      return;
    }
    illust.classList.add('is-animated');
    const nthSetState = nthAnimation;
    let stateCounter = 0;
    const stateInterval = setInterval(() => {
      setRandomState(illust);
      stateCounter++;
      if (stateCounter > nthSetState) {
        setTimeout(() => {
          clearState(illust);
          clearInterval(stateInterval);
        }, animationInterval);
      }
    }, animationInterval);
  }, loopInterval);


  // kvAnimationMove(kv);
}

function kvAnimationMove(kv) {
  // update mousePosition
  const mousePosition = {
    targetX: 0,
    targetY: 0,
    x: 0,
    y: 0
  };

  const mouseCallbacks = [];
  const bg = kv.querySelector('.c-akv__content__bg');
  const bgHeight = bg.offsetHeight;
  const bgWidth = bg.offsetWidth;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  document.addEventListener('mousemove', (e) => {

    const diffWidth = Math.abs(windowWidth - bgWidth);
    const diffHeight = Math.abs( windowHeight - bgHeight );

    const centerX = window.innerWidth / 2;
    const centerY = window.innerHeight / 2;

    mousePosition.targetX = (e.clientX - centerX) * (diffWidth / windowWidth);
    mousePosition.targetY = (e.clientY - centerY) * (diffHeight / windowHeight);
  });

  const update = () => {
    const speed = 0.01;
    mousePosition.x += (mousePosition.targetX - mousePosition.x) * speed;
    mousePosition.y += (mousePosition.targetY - mousePosition.y) * speed;
    mouseCallbacks.forEach(cb => {
      cb(mousePosition);
    });
    requestAnimationFrame(update);
  }
  requestAnimationFrame(update);

  Array.from(kv.querySelectorAll('.a-kv-item')).map(item => {
    const move = item.querySelector('.a-kv-item__move');
    mouseCallbacks.push(({x, y}) => {
      move.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    });

  });

}

function setupIntersectionObserver() {
  const targets = document.querySelectorAll('.js-observe');
  targets.forEach(target => {
    const rootMargin = target.getAttribute('data-root-margin');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const observerClass = entry.target.getAttribute('data-observer-class');
          if ( observerClass ) {
            entry.target.classList.add(observerClass);
          } else {
            entry.target.classList.add('is-obserbed');
          }
          observer.unobserve(entry.target);
        }
      });
    }
    , {
      rootMargin: rootMargin || '-30% 0px',
    });
    observer.observe(target);
  })
}
