import VirtualScroll from 'virtual-scroll';


export function vscroll(el) {

  if ( !el ) return null;

  const scroller = new VirtualScroll({
    el: el,
    mouseMultiplier: 0.5,
    firefoxMultiplier: 30,
    touchMultiplier: 2.5,
    useKeyboard: false,
    passive: true
  });
  scroller.on(e => {
    data.targetDeltaX = e.deltaY;
    data.targetDeltaY = e.deltaX;
    // console.log(e);
  });
  const data = {
    time: 0,
    delta: 0,
    deltaY: 0,
    targetDeltaY: 0,
    deltaX: 0,
    targetDeltaX: 0,
  
  }
  const scrollCallbacks = [];

  const loop = (time) => {
    data.delta = time - data.time;
    data.time = time;
    data.deltaY += (data.targetDeltaY - data.deltaY) * 0.5;
    data.deltaX += (data.targetDeltaX - data.deltaX) * 0.5;

    // data.deltaX = 1
    // data.deltaY = 1
    scrollCallbacks.forEach(fn => {
      fn(data);
    });
    requestAnimationFrame((time) => {
      loop( time );
    });
  }

  requestAnimationFrame((time) => {
    loop( time );
  });

  return {
    on: (name, fn) => {
      const map = {
        scroll: () => scrollCallbacks.push(fn),
      }
      map[name] && map[name]();
    },
    offScroll: () => {
      scroller.destroy();
      scrollCallbacks.length = 0;
    },
    startScroll: () => {
      scroller.init();
    },

  }
}

export const getTranslate = (element) => {
  const style = window.getComputedStyle(element);
  const transform = style.transform;
  const matrix = transform.replace('matrix(', '').replace(')', '').split(',')
  if (matrix.length !== 6) {
    return 0;
  }
  const replace = (s) => {
    if (!s) { return 0; }
    return parseFloat( s.replace('px', ''), 10 );
  }
  return [
    replace(matrix[4]),
    replace(matrix[5]),
    replace(matrix[6])
  ]
}

